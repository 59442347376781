export const environment = {
  env: 'test',
  provider: 'https://fedauth.qa.alaskasworld.com',
  clientId: 'WPOw7rTFe5rrc1LdQF',
  apiKey: '207f494686ab4a7595a9b0e4967bddb6',
  instrKey: 'cb03b80c-e841-461f-8d0a-ea4491a5f28b',
  apiFTCURL: 'https://apis.test.alaskaair.com/1/crew/training/lms/',
  logTarget: 'AppInsight', // Possible values : Console,AppInsight,None
  allowedGroups: 'as inflight training instructors,IT_VXQualEng,aad.crew.azureaccess,aad.crewqa.azureaccess,CFA Firefly',
  isProduction: false,
  idleInactiveMaxTime: 3599, // Seconds of inactivity until idle state entered
  // idleWarningCountdown is very imprecise and the "ticks" slow down over time esp when in background
  idleWarningCountdown: 5, // Seconds until full time-out (warning/countdown)
  idleLoggingEnabled: false,
};
